import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import { Link, Section } from '../../components';
import { Layout } from '../../layout';

export default function NotFoundPage() {
  const { file, site } = useStaticQuery(query);

  return (
    <Layout>
      <Section className="narrow">
        <span className="not-found-image">
          <GatsbyImage
            image={file.childImageSharp.gatsbyImageData}
            alt="Not found image"
          />
        </span>
        <h1 className="xxl-type">Oh no!</h1>
        <h2>This page doesn’t exist.</h2>
        <p>Why not try one of these:</p>

        <ul className="nav-list">
          {site.siteMetadata.notFoundSuggestedLinks.map((link: any) => (
            <li key={link.href}>
              <Link to={link.href}>{link.label}</Link>
            </li>
          ))}
        </ul>
      </Section>
    </Layout>
  );
}

const query = graphql`
  {
    site {
      siteMetadata {
        notFoundSuggestedLinks {
          href
          label
        }
      }
    }
    file(relativePath: { eq: "not_found/mechanic.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, placeholder: NONE, layout: FIXED)
      }
    }
  }
`;
